import PropTypes from 'prop-types';
import React from 'react';
import MegaMenu from 'brastrap/global/mega-menu/MegaMenu';

const MegaMenus = ({
  gridModifiers,
  menus,
  onClose,
  showContent,
  displayMegaMenuHeader,
  onBackButtonClick,
  locale,
  isDesktop
}) => (
  <div className="c-mega-menus"> 
    {menus.filter(x => !x.disabledMenuItem).map((menu, key) => (
      <MegaMenu
        {...{
          gridModifiers,
          key,
          ...menu,
          onClose,
          showContent,
          displayMegaMenuHeader,
          onBackButtonClick,
          locale,
          isDesktop
        }}
      />
    ))}
  </div>
);

MegaMenus.propTypes = {
  gridModifiers: PropTypes.arrayOf(PropTypes.string),
  menus: PropTypes.array,
  onClose: PropTypes.func,
  showContent: PropTypes.bool,
  displayMegaMenuHeader: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  locale: PropTypes.object,
  isDesktop: PropTypes.bool
};

MegaMenus.defaultProps = {
  gridModifiers: [],
  menus: [],
  onClose: () => {},
};

export default MegaMenus;
